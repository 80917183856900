import * as React from 'react';
import { clsx } from 'clsx';
import * as DialogPrimitives from '@radix-ui/react-dialog';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import DialogTitle from './dialog-title';
import DialogOverlay from './dialog-overlay';
import { XMarkIcon } from '@dop-ui/icons/react/dop/24';

import styles from './style.module.css';

export type AnimationStyles = 'popup' | 'slide-up' | 'slide-down';

export interface Props extends DialogPrimitives.DialogContentProps {
  /** @property 다이얼로그 타이틀 */
  title?: string;
  /** @property Portal 사용 여부 */
  usePortal?: boolean;
  /** @property (사전 정의된) 다이얼로그 크기 */
  // size?: DialogSizeType;
  /** @property 다이얼로그 높이를 컨텐츠에 맞추는 여부 설정 */
  autoHeight?: boolean;
  /** @property 임시 값 (뒷 배경 dim 처리를 위함) */
  useOverlay?: boolean;
  /** @property 오버레이 클릭 시 자동으로 닫히는 기능 차단 여부 (default=true) */
  useAutoClose?: boolean;
  /** @property (우측 상단) 닫기 버튼 노출 여부(default=true) */
  showCloseButton?: boolean;
  /** @property 다이얼로그 오픈 시 애니메이션 스타일 지정*/
  animation?: AnimationStyles;
  /** @property 다이얼로그 위치 (기본값='center') */
  position?: 'center' | 'top' | 'bottom';
}

export function Content({
  children,
  title,
  usePortal = true,
  useOverlay = false,
  useAutoClose = true,
  showCloseButton = true,
  className,
  animation,
  position = 'center',
  ...contentProps
}: React.PropsWithChildren<Props>) {
  const Container = usePortal ? DialogPrimitives.Portal : React.Fragment;

  const titlePart = title ? (
    <DialogTitle>{title}</DialogTitle>
  ) : (
    <VisuallyHidden.Root>
      <DialogTitle>No Title</DialogTitle>
    </VisuallyHidden.Root>
  );

  return (
    <Container>
      {useOverlay && <DialogOverlay className={styles.Dialog__Overlay_Open} />}
      <DialogPrimitives.Content
        className={clsx(
          styles.Dialog,
          position === 'center' ? styles.Dialog_PosCenter : undefined,
          position === 'top' ? styles.Dialog_PosTop : undefined,
          position === 'bottom' ? styles.Dialog_PosBottom : undefined,
          typeof animation !== 'undefined'
            ? styles.Dialog_Animation
            : undefined,
          position === 'center' && animation === 'popup'
            ? styles.Dialog_PosCenterPopup
            : undefined,
          (position === 'bottom' || position === 'top') && animation === 'popup'
            ? styles.Dialog_EdgePopup
            : undefined,
          animation === 'slide-up' ? styles.Dialog_SlideUp : undefined,
          animation === 'slide-down' ? styles.Dialog_SlideDown : undefined,
          className,
        )}
        onInteractOutside={(e) => {
          if (!useAutoClose) e.preventDefault();
        }}
        {...contentProps}
      >
        {showCloseButton && (
          <DialogPrimitives.Close className={styles.Dialog__Close} title="닫기">
            <XMarkIcon className={styles.Dialog__Close__Icon} />
          </DialogPrimitives.Close>
        )}
        {titlePart}

        {children}
      </DialogPrimitives.Content>
    </Container>
  );
}
