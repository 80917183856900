import * as React from 'react';

interface MessageDialogContextValue {
  alert: (message: string | React.ReactElement) => Promise<boolean>;
  confirm: (message: string | React.ReactElement) => Promise<boolean>;
}

export const Context = React.createContext<MessageDialogContextValue | null>(
  null,
);

export const useMessageDialog = () => {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error(
      'useMessageDialog must be used within a MessageDialogProvider',
    );
  }
  return context;
};
