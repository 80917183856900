import { PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import * as RadixPrimitives from '@radix-ui/react-toast';

import { useToastContext } from './toast-provider';
import { useToastStatusContext } from './toast-container';

import styles from './style.module.css';

export interface Props extends RadixPrimitives.ToastProps {
  open?: boolean;
  onOpenChange?(open: boolean): void;
}

export function Root({
  children,
  className = '',
  ...props
}: PropsWithChildren<Props>) {
  const {
    toastContext: { direction },
  } = useToastContext();

  const { open, setOpen } = useToastStatusContext();

  return (
    <RadixPrimitives.Root
      open={open}
      onOpenChange={setOpen}
      data-direction={direction}
      className={clsx(styles.ToastRoot, className)}
      {...props}
    >
      {children}
    </RadixPrimitives.Root>
  );
}

export default Root;
