import { Slot } from '@radix-ui/react-slot';

import { PropsWithChildren } from 'react';
import { useToastStatusContext } from './toast-container';

export interface TriggerProps {
  isPrevented: boolean;
  asChild?: boolean;
  className?: string;
}
function ToastTrigger({
  children,
  isPrevented,
  className = '',
  asChild = false,
}: PropsWithChildren<TriggerProps>) {
  const Comp = asChild ? Slot : 'button';

  const { open, setOpen } = useToastStatusContext();

  const handleOnClick = () => {
    if (!isPrevented) {
      setOpen(!open);
    }
  };
  return (
    <Comp className={className} onClick={handleOnClick}>
      {children}
    </Comp>
  );
}

export default ToastTrigger;
