'use client';

import type { PropsWithChildren } from 'react';
import type { i18n } from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  initTranslations,
  type Params as TranslationsInitializerParams,
} from './init-translations';

export interface Props extends TranslationsInitializerParams {}

/**
 * 다국어 제공자 (Provider)
 *
 * [참고]
 * - namespaces: 기본 값을 'common', 'component'로 설정함에 주의,
 *   명시적으로 주입할 경우 반드시 'common', 'component'를 포함해야 함.
 * @param param0
 * @returns
 */
export function TranslationsProvider({
  children,
  namespaces = ['common', 'component'],
  ...initParams
}: PropsWithChildren<Props>) {
  const [instance, setInstance] = useState<i18n | null>(null);
  const { locale } = initParams;

  useEffect(() => {
    const init = async () => {
      if (!instance) {
        const newInstance = await initTranslations({
          namespaces,
          ...initParams,
        });
        setInstance(newInstance);
      } else if (instance.language !== locale) {
        await instance.changeLanguage(locale);
      }
    };

    init().catch((e) => console.log(e));
  }, [instance, locale, initParams, namespaces]);

  if (!instance) {
    return null;
  }

  return (
    <I18nextProvider i18n={instance} defaultNS={namespaces[0]}>
      {children}
    </I18nextProvider>
  );
}

export default TranslationsProvider;
