'use client';

import * as React from 'react';
import type { LoginUserData } from '../../types';

export interface SessionContextValues {
  readonly me: LoginUserData | null;
  isLogin: () => boolean;
  logout: () => void;
}

export const SessionContext = React.createContext<SessionContextValues>({
  me: null,
  isLogin: () => false,
  logout: () => undefined,
});

export function useSessionContext() {
  return React.useContext(SessionContext);
}
