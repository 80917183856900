'use client';

import type { PropsWithChildren } from 'react';
import { SessionProvider } from '../../../features/authenticate';

export function AuthProvider({ children }: PropsWithChildren) {
  return <SessionProvider>{children}</SessionProvider>;
}

AuthProvider.displayName = 'Dopui.AuthProvider';

export default AuthProvider;
