import { PropsWithChildren } from 'react';
import * as RadixPrimitives from '@radix-ui/react-toast';

export interface Props extends RadixPrimitives.ToastDescriptionProps {}

export function Description({
  children,
  ...props
}: PropsWithChildren<RadixPrimitives.ToastDescriptionProps>) {
  return (
    <RadixPrimitives.Description {...props}>
      {children}
    </RadixPrimitives.Description>
  );
}

export default Description;
