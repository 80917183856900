import * as React from 'react';
import * as DialogPrimitives from '@radix-ui/react-dialog';

import styles from './style.module.css';

function Title(
  { children }: React.PropsWithChildren,
  ref?: React.ForwardedRef<HTMLHeadingElement>,
) {
  return (
    <DialogPrimitives.Title className={styles.Dialog__Title} ref={ref}>
      {children}
    </DialogPrimitives.Title>
  );
}

Title.displayName = 'Dialog.Title';

export default React.forwardRef(Title);
