'use client';

import { type PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryStreamedHydration } from '@tanstack/react-query-next-experimental';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export interface Props {
  /** 리패칭 대기 시간. 과도한 리패칭을 막기 위해 0보다 큰 수를 설정. */
  staleTime?: number;
  useStream?: boolean;
  useDevtools?: boolean;
}

let browserQueryClient: QueryClient | undefined = undefined;

function getQueryClient(staleTime: number) {
  if (typeof window === 'undefined') {
    // 서버사이드 렌더링 시에는 매 요청마다 새로운 쿼리 클라이언트를 생성
    return createQueryClient(staleTime);
  } else {
    if (!browserQueryClient) browserQueryClient = createQueryClient(staleTime);
    return browserQueryClient;
  }
}

export default function ReactQueryClientProvider({
  children,
  staleTime = 60 * 1000,
  useStream = false,
  useDevtools = true,
}: PropsWithChildren<Props>) {
  const queryClient = getQueryClient(staleTime);

  const childNode = useStream ? (
    <ReactQueryStreamedHydration>{children}</ReactQueryStreamedHydration>
  ) : (
    children
  );

  return (
    <QueryClientProvider client={queryClient}>
      {childNode}
      {useDevtools && (
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-left" />
      )}
    </QueryClientProvider>
  );
}

function createQueryClient(staleTime: number) {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime,
      },
    },
  });
}
