'use client';

import type { PropsWithChildren } from 'react';
import * as ReactQuery from '../../../shared/lib/react-query';
import {
  DopuiRootProvider,
  type Props as DopuiRootProviderProps,
} from './dop-ui-root-provider';

export interface Props extends DopuiRootProviderProps {}

/**
 * Next.js의 전역 Provider
 * @returns
 */
export function NextAppProvider({
  children,
  ...rest
}: PropsWithChildren<Props>) {
  return (
    <ReactQuery.QueryClientProvider useStream={true}>
      <DopuiRootProvider {...rest}>{children}</DopuiRootProvider>
    </ReactQuery.QueryClientProvider>
  );
}

NextAppProvider.displayName = 'Dopui.NextAppProvider';

export default NextAppProvider;
