import * as React from 'react';
import * as Dialog from '../../../shared/ui/dialog';
import { Button } from '../../../shared/ui/button';
import { useTranslation } from '../../../shared/lib/i18n/client';
// import { XMarkIcon } from '@dop-ui/icons/react/dop/24';

import style from './style.module.css';

export interface Props {
  type: 'alert' | 'confirm' | null;
  open?: boolean;
  asChild?: boolean;
  onOpenChange?: (open: boolean) => void;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export function MessageDialog({
  children,
  type,
  open,
  asChild,
  onOpenChange,
  onConfirm,
  onCancel,
}: React.PropsWithChildren<Props>) {
  const { t } = useTranslation('component');

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content
        position="center"
        animation="popup"
        usePortal
        useOverlay
        useAutoClose={false}
        className={style.MessageDialog}
      >
        {/** 메시지 본문 */}
        <Dialog.Description
          className={style.MessageDialog__Body}
          asChild={asChild}
        >
          {children}
        </Dialog.Description>

        {/** 버튼 */}
        <div className={style.MessageDialog__Footer}>
          {type === 'confirm' && (
            <Button
              onClick={onCancel}
              size="medium"
              styleType="ghost"
              colorset="minor"
            >
              {t('widget.MessageDialog.cancel')}
            </Button>
          )}
          <Button
            onClick={onConfirm}
            size="medium"
            styleType="solid"
            colorset="major"
          >
            {t('widget.MessageDialog.confirm')}
          </Button>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}

export default MessageDialog;
