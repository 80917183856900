import * as React from 'react';

export interface Props {
  title?: string;
}

export function TooltipPreviewContent({
  children,
  title,
}: React.PropsWithChildren<Props>) {
  return (
    <dl id="previewContentLayer" className="preview">
      {title && <dt>{title}</dt>}
      <dd>{children}</dd>
    </dl>
  );
}

export default TooltipPreviewContent;
