import * as React from 'react';
import * as RadixPrimivites from '@radix-ui/react-tooltip';
import { clsx } from 'clsx';
import styles from './tooltip.module.css';

export const Provider = RadixPrimivites.Provider;
export const Root = RadixPrimivites.Root;
export const Trigger = RadixPrimivites.Trigger;
export const Portal = RadixPrimivites.Portal;

function ForwardRefExoticContent(
  {
    children,
    side = 'bottom',
    align = 'start',
    className,
    ...props
  }: RadixPrimivites.TooltipContentProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <RadixPrimivites.Content
      ref={ref}
      side={side}
      align={align}
      className={clsx(styles.Content, className)}
      {...props}
    >
      {children}
    </RadixPrimivites.Content>
  );
}

export const Content = React.forwardRef(ForwardRefExoticContent);
Content.displayName = 'Tooltip.Content';

// re-export @radix-ui/react-tooltip types
export type {
  TooltipProviderProps,
  TooltipProps,
  TooltipTriggerProps,
  TooltipPortalProps,
  TooltipContentProps,
} from '@radix-ui/react-tooltip';
