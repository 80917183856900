import type { PropsWithChildren } from 'react';
import {
  dehydrate,
  HydrationBoundary,
  QueryClient,
  type FetchQueryOptions,
} from '@tanstack/react-query';

export interface Props<TQueryFnData> extends FetchQueryOptions<TQueryFnData> {}

export default async function PrefetchQuery<TQueryFnData>({
  children,
  ...props
}: PropsWithChildren<Props<TQueryFnData>>) {
  const queryClient = new QueryClient();

  await queryClient.prefetchQuery(props);

  return (
    <HydrationBoundary state={dehydrate(queryClient)}>
      {children}
    </HydrationBoundary>
  );
}
