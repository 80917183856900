import * as React from 'react';
import { Context } from './context';
import { MessageDialog } from './message-dialog';

interface MessageDialogState {
  type: 'alert' | 'confirm' | null;
  open: boolean;
  message: string | React.ReactElement | null;
  resolve: ((value: boolean) => void) | null;
}

export function Provider({ children }: React.PropsWithChildren) {
  const [dialogState, setDialogState] = React.useState<MessageDialogState>({
    type: null,
    open: false,
    message: null,
    resolve: null,
  });

  const confirm = React.useCallback((message: string | React.ReactElement) => {
    return new Promise<boolean>((resolve) => {
      setDialogState({
        type: 'confirm',
        open: true,
        message,
        resolve,
      });
    });
  }, []);

  const alert = React.useCallback((message: string | React.ReactElement) => {
    return new Promise<boolean>((resolve) => {
      setDialogState({
        type: 'alert',
        open: true,
        message,
        resolve,
      });
    });
  }, []);

  const close = () => {
    setDialogState({ type: null, open: false, message: null, resolve: null });
  };

  const handleResolve = React.useCallback(() => {
    if (dialogState.resolve) {
      dialogState.resolve(true);
      close();
    }
  }, [dialogState]);

  const handleReject = React.useCallback(() => {
    if (dialogState.resolve) {
      dialogState.resolve(false);
      close();
    }
  }, [dialogState]);

  const ctxVal = {
    alert,
    confirm,
  };

  const isTextMessage = typeof dialogState.message === 'string';
  const dialogMessage = dialogState.message;

  return (
    <Context.Provider value={ctxVal}>
      {children}

      {dialogState.type && (
        <MessageDialog
          type={dialogState.type}
          open={dialogState.open}
          onOpenChange={() => close()}
          onConfirm={handleResolve}
          onCancel={handleReject}
          asChild={!isTextMessage}
        >
          {dialogMessage}
        </MessageDialog>
      )}
    </Context.Provider>
  );
}

export default Provider;
