'use client';

import * as React from 'react';
import { LOGIN_PAGE_URL } from '../../../../shared/constants';
import { getSessionData } from '../../api/get-session-data';
import type { LoginUserData } from '../../types';
import { SessionContext, type SessionContextValues } from './session-context';

export function Provider({ children }: React.PropsWithChildren) {
  const [userData, setUserData] = React.useState<LoginUserData | null>(null);
  const firstLoadedRef = React.useRef<boolean>(false);
  const clearStorages = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  React.useLayoutEffect(() => {
    if (!firstLoadedRef.current) {
      getSessionData()
        .then((data) => setUserData(data))
        .catch((e) => console.error(e));
      firstLoadedRef.current = true;
    }
  }, [firstLoadedRef]);

  const logout = async () => {
    try {
      const response = await fetch('/api/portal/common/auth/logout', {
        method: 'POST',
      });

      if (response.ok) {
        setUserData(null);
        window.location.href = LOGIN_PAGE_URL;
        clearStorages();
      }
    } catch (e) {
      console.log(`logout error:`, e);
    }
  };

  const ctxVal: SessionContextValues = {
    get me() {
      return userData;
    },
    isLogin: () => userData !== null,
    logout: () => void logout(),
  };

  // user 정보를 못받아오면 로그인 페이지로 이동하지만, 한번 더 방어한다.
  if (userData === null) return;

  return (
    <SessionContext.Provider value={ctxVal}>{children}</SessionContext.Provider>
  );
}

Provider.displayName = 'Dopui.SessionProvider';

export default Provider;
