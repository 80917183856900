import { fetchGraphQL } from '../../../shared/lib/fetch/client';
import type { LoginUserData, MeResponse, UserData } from '../types';
import * as userSessionQuery from '../model/user-session-query';

function userDataMapper(data: UserData): LoginUserData {
  return {
    id: data.id,
    name: data.name,
    loginId: data.loginId,
    email: data.email ?? 'no-email',
    gradeName: data.userInfo?.grade?.name ?? 'no-grade',
    departmentMemberList: [],
    status: data.status ?? 'no-status',
    companyId: data.company ? `${data.company.id}` : 'no-company',
    companyGroupId: data.company ? data.company.companyGroupId : 'no-company',
    profileImageUrl: data.userInfo.profileImageUrl ?? '',
    isBusinessPortalAccessible: data.isAccessibleBusinessPortal ?? false,
    isSettingAccessible: data.isAccessibleSettingPortal ?? false,
    isAccessibleArchivingService: data.isAccessibleArchivingService ?? false,
  };
}

export async function getSessionData() {
  const resp = await fetchGraphQL({
    query: userSessionQuery.Query,
    operationName: userSessionQuery.ID,
  });

  const data = (await resp.json()) as MeResponse;

  return userDataMapper(data.data.me);
}
