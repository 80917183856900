import * as React from 'react';
import { clsx } from 'clsx';
import * as DialogPrimitives from '@radix-ui/react-dialog';

import styles from './style.module.css';

export interface Props {
  className?: string;
}

function Overlay(
  { children, className }: React.PropsWithChildren<Props>,
  ref?: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <DialogPrimitives.Overlay
      className={clsx(styles.Dialog__Overlay, className)}
      ref={ref}
    >
      {children}
    </DialogPrimitives.Overlay>
  );
}

Overlay.displayName = 'Dialog.Overlay';

export default React.forwardRef(Overlay);
