import type { PropsWithChildren } from 'react';
import { ClientTranslationsProvider, type ImportFn } from '../client';

export interface Props {
  locale?: string;
  loadResource?: ImportFn;
  namespaces?: string[];
}

export const CLINET_NAMESPACES = ['common'];

export function TranslationsProvider({
  children,
  locale = 'ko',
  loadResource,
  namespaces = CLINET_NAMESPACES,
}: PropsWithChildren<Props>) {
  return (
    <ClientTranslationsProvider
      locale={locale}
      namespaces={namespaces}
      loadResource={loadResource}
    >
      {children}
    </ClientTranslationsProvider>
  );
}

export default TranslationsProvider;
